@import '/src/assets/styles/colors';

.order-group-div-tr {
  box-shadow: none !important;
  background-color: map-get($colors, 'gray-off-color');
  
  &:hover {
    background-color: map-get($colors, 'gray-off-color') !important;
  }

  & > td {
    padding: 0px;
  }

  .order-group-div-hr {
    margin: 0px;
  }
  
}