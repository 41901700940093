@import '/src/assets/styles/colors';

.selection {
  position: relative;
  margin: 0;

  &.type--checkbox {
    appearance: none;

    width: 20px;
    height: 20px;
    background-color: white;
    border: 1px solid map-get($colors, 'primary-color');
    border-radius: 4px;

    &:disabled {
      border: 1px solid map-get($colors, 'gray-color') !important;

      &:checked {
        background-color: map-get($colors, 'gray-color') !important;
      }
    }

    &:checked {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='12' viewBox='0 0 14 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.6247 0.219168C14.056 0.564178 14.1259 1.19347 13.7809 1.62473L5.78087 11.6247C5.60318 11.8468 5.33931 11.9828 5.0553 11.9985C4.77129 12.0142 4.49403 11.9083 4.29289 11.7071L0.292893 7.70714C-0.0976311 7.31662 -0.0976311 6.68345 0.292893 6.29293C0.683417 5.90241 1.31658 5.90241 1.70711 6.29293L4.91708 9.5029L12.2191 0.375342C12.5641 -0.0559202 13.1934 -0.125842 13.6247 0.219168Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;

      background-color: map-get($colors, 'primary-color');
    }
  }

  &.type--toggle {
    appearance: none;

    width: 44px;
    height: 24px;

    border-radius: 44px;
    background-color: map-get($colors, 'gray-dark-color');

    padding: 4px;

    &:disabled {
      background-color: map-get($colors, 'gray-color') !important;
    }

    &:checked {
      background-color: map-get($colors, 'primary-color');
    }

    &::after {
      content: '';

      position: absolute;

      height: 16px;
      width: 16px;
      border-radius: 50%;
      background-color: white;
      transform: translateX(0);

      transition: transform 0.3s;
    }

    &:checked {
      &::after {
        transform: translateX(calc(100% + 4px)); // (padding-left, right) / 2
      }
    }
  }

  &.type--radio {
    appearance: none;

    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    border: 2px solid map-get($colors, 'primary-color');

    &:disabled {
      border: 2px solid map-get($colors, 'gray-color');
    }

    &:checked {
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background-color: map-get($colors, 'primary-color');
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      &:disabled {
        &::after {
          background-color: map-get($colors, 'gray-color') !important;
        }
      }
    }
  }
}
