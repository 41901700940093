@import '/src/assets/styles/colors';

.container {
  display: flex;
  flex-direction: row;

  background-color: map-get($colors, 'white');
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 8px;

  padding-top: 16px;
  padding-bottom: 16px;

  margin-bottom: 16px;

  align-items: center;
}
