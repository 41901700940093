@import '/src/assets/styles/colors';

.label {
  display: flex;

  color: map-get($colors, 'gray-color');

  &.bold {
    font-weight: bold;
  }

  .icon {
    display: inline-block;
    margin-right: 3px;

    path {
      fill: map-get($colors, 'gray-color');
    }
  }
}
