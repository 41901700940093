@import '/src/assets/styles/colors';

.arrow-icon {
  path {
    fill: map-get($colors, 'gray-dark-color');
  }

  &.disabled {
    path {
      fill: map-get($colors, 'gray-color') !important;
    }
  }
}
