@import '/src/assets/styles/colors';

.text {
  margin: 0;

  &.white-space--nowrap {
    white-space: nowrap;
  }

  &.white-space--wrap {
    white-space: wrap;
  }

  &.center {
    text-align: center;
  }
}
