@import '/src/assets/styles/colors';

.delivery-form-summary-wrapper {
  padding: 36px;
  background-color: map-get($colors, 'white');
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 8px;

  .item {
    flex: 1 1;
  }
}
