@import '/src/assets/styles/colors';

.container {
  position: relative;
  display: inline-block;
  width: max-content;
  height: max-content;

  max-width: 792px;
}
