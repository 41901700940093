@import '/src/assets/styles/colors';
@import '/src/assets/styles/size';

.btn {
  font-weight: bold;

  padding: 12px 16px !important;

  & > :global(.MuiButton-label) {
    align-content: space-between !important;
    justify-content: space-between !important;
  }

  &.fullwidth {
    width: 100% !important;
  }

  &.disabled {
    background-color: map-get($colors, 'gray-color') !important;
    color: white !important;
    box-shadow: none !important;
  }

  &.border {
    border: 1px solid black;
  }

  &.disabled-box-shadow {
    box-shadow: none !important;
  }

  @mixin type($background-color, $font-color, $hover-color) {
    background-color: $background-color;
    color: $font-color;

    &:hover {
      text-decoration: none;
      background-color: $hover-color;
    }
  }

  &.type--black {
    @include type(map-get($colors, 'black'), white, map-get($colors, 'black'));
  }

  &.type--white {
    @include type(map-get($colors, 'white'), white, map-get($colors, 'white'));
  }

  &.type--primary-color {
    @include type(
      map-get($colors, 'primary-color'),
      white,
      map-get($colors, 'primary-dark-color')
    );
  }

  &.type--secondary-color {
    @include type(
      map-get($colors, 'secondary-color'),
      white,
      map-get($colors, 'secondary-light-color')
    );
  }

  &.type--gray-color {
    @include type(
      map-get($colors, 'gray-color'),
      white,
      map-get($colors, 'secondary-light-color')
    );
  }

  &.type--transparent-color {
    @include type(transparent, black, rgba(0, 0, 0, 0.1));
  }

  &.type--gray-light-color {
    @include type(
      map-get($colors, 'gray-light-color'),
      black,
      map-get($colors, 'secondary-light-color')
    );
  }

  &.fit {
    min-width: 0 !important;
    width: max-content !important;
  }

  &.size--small {
    width: 45px;
    height: 19px;
    padding: 2px 6px !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }

  &.size--medium {
    padding: 12px 34px;
    width: 112px;
    height: 48px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }

  &.size--large {
    padding: 12px 34px;
    width: 248px;
    height: 48px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
  }

  .loading-circle {
    position: absolute;

    width: 100%;
    height: 100%;

    display: flex;

    left: 0;

    gap: 12px;

    align-items: center;
    justify-content: center;

    @keyframes slidein12 {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.3);
      }

      100% {
        transform: scale(1);
      }
    }

    .circle {
      width: 9px;
      height: 9px;

      border-radius: 50%;

      background-color: map-get($colors, 'gray-off-color');
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-name: slidein12;

      @for $i from 1 through 3 {
        &:nth-child(#{$i}n) {
          animation-delay: #{$i * 0.33}s;
        }
      }
    }
  }
}
