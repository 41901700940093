.container {
  width: 100%;
  height: 100%;

  .flex-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
