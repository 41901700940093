.item-wrapper {
  position: absolute;
  display: none;

  left: 50%;
  transform: translateX(-50%);

  width: max-content;
  height: max-content;

  z-index: 1;

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  .flex-left {
    justify-content: left;
  }

  &.size-type--medium {
    padding: 1rem;
  }

  &.show {
    display: flex;
  }
}
