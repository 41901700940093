@import '/src/assets/styles/colors';

.container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .header {
    padding: 24px;
    border-bottom: 1px solid map-get($colors, 'gray-color');
    flex: 1;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: visible;

    .title,
    .content {
      padding: 28px 42px;
      background-color: map-get($colors, 'white');
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
    }
  }
}
