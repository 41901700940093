@import '/src/assets/styles/colors';

.input {
  &[type='text'],
  &[type='password'],
  &[type='email'] {
    color: black;
    outline: none;
    border-left-width: 0;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;

    padding-left: 0;
    padding-right: 0;

    width: 100%;
    height: 100%;
    font-weight: bold;

    caret-color: map-get($colors, 'primary-color');
    background-color: map-get($colors, 'transparency');

    &::placeholder {
      color: map-get($colors, 'gray-color');
    }

    &.active {
      color: map-get($colors, 'primary-color');
    }

    &:focus {
      color: black !important;
    }

    &:disabled {
      color: map-get($colors, 'gray-color') !important;
    }
  }
}
