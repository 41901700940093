@import '/src/assets/styles/colors';

.icon {
  svg > path {
    fill: white;
  }

  &.active {
    svg > path {
      fill: map-get($colors, 'primary-dark-color') !important;
    }
  }
}
