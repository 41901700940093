.container {
  list-style: none;
  padding: 0;
  margin: 0;

  &.gap--xsmall {
    & > :not(:last-child) {
      margin-bottom: 4px;
    }
  }

  &.gap--small {
    & > :not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &.gap--medium {
    & > :not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &.gap--large {
    & > :not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &.gap--xlarge {
    & > :not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &.gap--xxlarge {
    & > :not(:last-child) {
      margin-bottom: 32px;
    }
  }
}
