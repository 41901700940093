.header-container {
  display: flex;
  gap: 77px;

  .flex {
    display: flex;
    gap: 16px;

    align-items: center;
  }
}

.order-btn {
  border: 1px solid black;
  cursor: pointer;
}

.table {
  border-collapse: collapse;

  th, td {
    padding: 8px;
    border: 1px solid black;
  }
}