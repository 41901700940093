@import '/src/assets/styles/colors';

.container {
  display: flex;

  justify-content: space-between;

  padding-bottom: 30px;

  border-bottom: 1px solid map-get($colors, gray-dark-color);

  .title-container {
    display: flex;

    align-items: center;

    padding-left: 16px;

    .right-icon {
      margin-right: 16px;
      margin-left: 16px;
    }

    .next-page-container {
      display: flex;

      .contour {
        border-left: 2px solid map-get($colors, 'gray-color');
        border-right: 2px solid map-get($colors, 'gray-color');

        margin-left: 16px;
        margin-right: 16px;
      }

      .right-arrow-icon {
        align-self: center;
        margin-left: 4px;
      }
    }
  }
}
