@import '/src/assets/styles/colors';

.container {
  position: relative;
  display: inline-block;

  .toast {
    width: max-content;
    position: absolute;

    &.position--top {
      top: 50%;
      left: 50%;

      transform: translate(-50%, -100%);
    }

    &.position--right {
      top: 50%;
      right: -50%;

      transform: translate(100%, -50%);
    }

    padding: 8px;

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    background-color: rgba(map-get($colors, 'gray-dark-color'), 1);
    visibility: hidden;
    opacity: 0;

    transition: visibility 0.3s, opacity 0.3s linear;

    &.active {
      visibility: visible;
      opacity: 1;
    }
  }
}