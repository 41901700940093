.container {
  z-index: 101;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  display: none;

  &.size--small {
    padding: 16px;
    width: 280px;
  }

  &.size--large {
    padding: 16px;
    width: 720px;
  }

  &.open {
    display: block;
  }
}
