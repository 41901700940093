@import '/src/assets/styles/colors';

.container {
  .tooltip {
    position: fixed;
    background: map-get($colors, 'gray-dark-color');
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    z-index: 1;

    transition: all 0.1s ease 0s;
    // transition:  0.1s ease 0s;

    &.size--xxsmall {
      padding: 4px;
    }

    &.size--xsmall {
      padding: 8px;
    }

    &.size--small {
      padding: 12px;
    }

    &.size--medium {
      padding: 16px;
    }
  }
}
