@import '/src/assets/styles/colors';

.container {
  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  &.active {
    background-color: rgba(map-get($colors, 'primary-color'), 0.3);
  }

  &:hover {
    background-color: rgba(map-get($colors, 'primary-color'), 0.3);
  }

  &.size--large {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &.size--medium {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  &.size--small {
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
}
