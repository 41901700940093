.content {
  flex-grow: 1;
  margin-top: 36px;

  // width: max-content;
  min-width: 1200px;
  min-height: max-content;

  display: flex;
  flex-direction: column;

  gap: 24px;

  margin-bottom: 50px;
}

.grid {
  gap: 48px;

  min-height: 100vh;

  display: grid;
  grid-template-columns: 1fr 11fr;
  grid-template-rows: 1fr;
}

.root {
  padding-right: 48px;
}

.background {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;

  z-index: 100;
}
