@import '/src/assets/styles/colors';

.container {
  background-color: map-get($colors, 'white');

  tr {
    &:hover {
      background-color: map-get($colors, 'gray-light-color');
    }
  }
}
