@import '/src/assets/styles/colors';

.list-item-group {
  .margin {
    &.line {
      border-bottom: 1px solid map-get($colors, 'primary-color');
    }

    &:not(:first-child) {
      margin-top: 4px;
    }

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}
