.setting-dropdown-item {
  display: flex;
  align-items: center;
  gap: 16px;

  :nth-child(2) {
    flex: 2;
  }
}

.date-flex {
  display: flex;
  gap: 16px;
  justify-content: center;
}
