.container {
  display: inline-grid;

  column-gap: 8px;

  grid-template-columns: auto auto;
  grid-auto-rows: auto;
  align-items: center;

  justify-content: center;
}
