.header-container {
  display: flex;
  gap: 77px;

  .flex {
    display: flex;
    gap: 16px;

    align-items: center;
  }
}
