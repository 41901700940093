@import '/src/assets/styles/colors';

.container {
  display: grid;

  gap: 24px;

  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  // grid-template-rows: 0.1fr 0.1fr;

  .total-order-count-wrapper {
    grid-column: span 4;

    padding: 32px 24px 24px 24px;
    background-color: map-get($colors, 'white');

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 16px;

    .header {
      display: flex;
      justify-content: space-between;
    }

    .chart-tooltip {
      padding: 16px;
      background-color: map-get($colors, 'white');
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
    }
  }

  .one-item {
    grid-column: span 1;

    padding: 32px 24px 24px 24px;
    background-color: map-get($colors, 'white');

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
  }

  .two-item {
    grid-column: span 2;

    padding: 32px 24px 24px 24px;
    background-color: map-get($colors, 'white');

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 16px;
  }
}
