@import '/src/assets/styles/colors';

.container {
  display: flex;
  align-items: center;
  gap: 8px;

  &.full-width {
    width: inherit !important;
  }

  &.size--xxlarge {
    width: 262px;
  }

  &.size--medium,
  &.size--large {
    width: 256px;
  }

  &.type--search {
    padding: 12px;
    width: 331px;
    border: 1px solid map-get($colors, 'black');
    border-radius: 8px;
  }
}
