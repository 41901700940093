.container {
  display: none;
  padding: 16px;

  max-width: 560px;
  min-width: 400px;

  width: max-content;
  
  &.active {
    display: flex;
  }
}