@import '/src/assets/styles/colors';

.container {
  width: 100%;
  height: max-content;
  // max-width: 2400px;

  border-collapse: separate;

  &.table_type {
    border-spacing: 0 16px;
  }
}
