@import '/src/assets/styles/colors';

.img {
  border-radius: 50%;
  display: flex;
  position: relative;

  background-color: map-get($colors, 'gray-light-color');

  background-position: 0 0, center center;
  background-repeat: no-repeat, no-repeat;

  &.size-type--small {
    background-size: cover, 34px 34px;
    width: 34px;
    height: 34px;
  }

  &.size-type--medium {
    background-size: cover, 94px 94px;
    width: 94px;
    height: 94px;
  }

  &.outline {
    border-color: map-get($colors, 'primary-color');
    border-width: 3px;
    border-style: solid;
  }

  // 정확히 사이즈를 맞춰서 줄일것인가..
  // background-size: 94px 94px;

  justify-items: center;
  align-items: center;

  position: relative;

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 32px;
    height: 32px;
    background-color: map-get($colors, 'gray-light-color');
    border-radius: 50%;
    border: map-get($colors, 'primary-color') 3px solid;

    left: 0;
    top: 0;

    transform: translateY(-6px);
  }
}
