@import '/src/assets/styles/_colors.scss';

.container {
  display: inline-block;
  width: max-content;

  &.full-width {
    width: 100%;
  }

  .contour {
    margin-top: 4px;
    border-bottom: 1px solid map-get($colors, 'gray-dark-color');

    &.active {
      border-bottom-color: map-get($colors, 'primary-color');
    }

    &.disabled {
      border-color: map-get($colors, 'gray-color');
    }
  }
}
