@import '/src/assets/styles/colors';

.btn {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 16px !important;
  padding-right: 16px !important;

  border-radius: 4px !important;

  &.active {
    background-color: rgba(map-get($colors, 'primary-dark-color'), 0.3);
    .content {
      color: white;
    }

    :global(.MuiButton-startIcon) {
      path {
        fill: white;
      }
    }
  }

  :global(.MuiButton-startIcon) {
    position: absolute;
    left: 4px + 16px;

    path {
      fill: map-get($colors, 'primary-dark-color');
    }
  }

  &:hover {
    text-decoration: none !important;
    background-color: rgba(black, 0.25) !important;
  }

  .content {
    color: map-get($colors, 'primary-dark-color');

    &.size-type--medium {
      font-size: 19px;
    }

    &.size-type--small {
      font-size: 16px;
    }
    text-align: center;
    margin: 0;
    vertical-align: middle;
    font-weight: bold;
  }
}
