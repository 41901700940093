@import '/src/assets/styles/colors';

.container {
  :global(.MuiButton-label) {
    gap: 60px;
    justify-content: flex-start;

    :global(.MuiButton-startIcon) {
      margin: 0;
      position: relative;
      left: 0;
      width: 24px;
    }
  }
}

div.active {
  position: absolute;
  width: 5px;
  height: 53px;
  background-color: map-get($colors, 'primary-dark-color');
  display: inline;
  border-radius: 4px 0px 0px 4px;
  right: 0;
}
