@import '/src/assets/styles/colors';

.title-container {
  display: flex;
  justify-content: space-between;
  height: auto;
  position: relative;
  cursor: pointer;

  align-items: center;

  gap: 4px;

  user-select: none;
}

.bottom-line {
  margin-top: 4px;

  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0;

  border-style: solid;
  border-bottom-width: 1px;
  border-color: map-get($colors, 'gray-dark-color');
  transition: border 100ms ease-out;

  &.active {
    border-color: map-get($colors, 'primary-color');
    color: black;
  }

  &.disabled {
    border-color: map-get($colors, 'gray-color') !important;
  }
}
