@import '/src/assets/styles/colors';
@import '/src/assets/styles/size';

.font-weight--bold {
  font-weight: bold;
}

.font-weight--normal {
  font-weight: normal;
}

@mixin generate-size() {
  @each $name, $font in $font-sizes {
    .font-size--#{$name} {
      font-size: map-get($font, 'size');
      line-height: map-get($font, 'line-height');
    }
  }
}

// $transparency는 bg만 해당
@mixin generate-color($transparency: 10) {
  @each $name, $color in $colors {
    @include generate-background-color($name, $color, $transparency);
    @include generate-font-color($name, $color);
    @include generate-svg-fill-color($name, $color);
    @include generate-border-color($name, $color);
  }
}

@mixin generate-background-color($name, $color, $transparency) {
  .bg-color--#{$name} {
    background-color: rgba($color, $transparency);
  }
}

@mixin generate-font-color($name, $color) {
  .font-color--#{$name} {
    color: $color;
  }
}

@mixin generate-svg-fill-color($name, $color) {
  .fill--#{$name} {
    path {
      fill: $color;
    }
  }
}

@mixin generate-border-color($name, $color) {
  .border-color--#{$name} {
    border-color: $color !important;
  }
}

@include generate-size();
@include generate-color();

.text-align--left {
  text-align: left;
}

.text-align--right {
  text-align: right;
}

.text-align--center {
  text-align: center;
}

$grid-spacing: 0;

.col-1 {
  width: (100% / 12) - ($grid-spacing * 11 / 12);
}
.col-2 {
  width: (100% / 6) - ($grid-spacing * 10 / 12);
}
.col-3 {
  width: (100% / 4) - ($grid-spacing * 9 / 12);
}
.col-4 {
  width: (100% / 3) - ($grid-spacing * 8 / 12);
}
.col-5 {
  width: (100% / 2.4) - ($grid-spacing * 7 / 12);
}
.col-6 {
  width: (100% / 2) - ($grid-spacing * 6 / 12);
}
.col-7 {
  width: (100% / 1.714285714285714) - ($grid-spacing * 5 / 12);
}
.col-8 {
  width: (100% / 1.5) - ($grid-spacing * 4 / 12);
}
.col-9 {
  width: (100% / 1.333333333333333) - ($grid-spacing * 3 / 12);
}
.col-10 {
  width: (100% / 1.2) - ($grid-spacing * 2 / 12);
}
.col-11 {
  width: (100% / 1.090909090909091) - ($grid-spacing * 1 / 12);
}
.col-12 {
  width: 100%;
}

.display-none {
  display: none;
}

.flex {
  display: flex;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-justify-content-center {
  justify-content: center;
}

.flex-align-items-center {
  align-items: center;
}
