.flex {
  display: flex;

  &.flex-type--inline-flex {
    display: inline-flex !important;
  }

  &.direction--column {
    flex-direction: column;
  }

  &.justify-content--center {
    justify-content: center;
  }

  &.justify-content--space-between {
    justify-content: space-between;
  }

  &.align-items--center {
    align-items: center;
  }

  &.align-items--end {
    align-items: end;
  }

  &.align-items--baseline {
    align-items: baseline;
  }

  &.align-items--start {
    align-items: flex-start;
  }

  &.direction--row {
    flex-direction: row;
  }

  &.gap--xsmall {
    gap: 4px;
  }

  &.gap--small {
    gap: 8px;
  }

  &.gap--medium {
    gap: 12px;
  }

  &.gap--large {
    gap: 16px;
  }

  &.gap--xlarge {
    gap: 24px;
  }

  &.gap--xxlarge {
    gap: 36px;
  }
}
