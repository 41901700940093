@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import '/src/assets/styles/base.class.style';

* {
  font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  font-size: 16px;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: map-get($colors, 'gray-off-color');
}

a {
  text-decoration: none;
}
