.container {
  display: flex;
  align-content: center;
  align-items: center;
  gap: 70px;

  flex-direction: column;

  .logo-container {
    display: flex;
    flex-direction: column;
  }

  .signin-form-container {
    position: relative;
    display: flex;

    flex-direction: column;

    gap: 36px;

    width: 248px;

    .group {
      display: flex;
      flex-direction: column;

      gap: 4px;
    }
  }
}
