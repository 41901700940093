$colors: (
  'primary-color': #498c74,
  'primary-dark-color': #384d46,
  'primary-light-color': #77ab9a,
  'gray-off-color': #f8f8f8,
  'gray-light-color': #eaeaea,
  'gray-color': #c4c4c4,
  'gray-dark-color': #727272,
  'secondary-color': #de3a3a,
  'secondary-light-color': #ed9292,
  'black': #000000,
  'white': #ffffff,
  'blue-color': #3a72de,
  'transparency': rgba(0, 0, 0, 0)
);
