@import '/src/assets/styles/colors';

.drawer {
  width: 240px;
  flex-shrink: 0;

  .cs-container {
    margin-top: 50px;

    .title {
      color: white;
      font-size: 16px;
      padding-left: 20px;
      font-weight: bold;
    }
  }

  @media screen and (min-width: 2048px) {
    width: 280px;
  }
}

.drawerPaper {
  top: 0;
  bottom: 0;
  height: auto !important;

  z-index: 10 !important;
  width: 262px;
  background-color: map-get($colors, 'primary-color') !important;
  margin-top: 30px;
  margin-bottom: 30px;
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25));
  border-radius: 0px 8px 8px 0px;

  padding: 16px 10px 16px 10px;

  @media screen and (min-width: 2048px) {
    width: 282px;
  }
}

.logo-img {
  max-width: 140px;
}

.dashboard-container,
.cs-container {
  margin-top: 50px;
}

.menu-list-btn {
  &:not(:first-child) {
    margin-top: 3px;
  }
}

.footer-container {
  position: relative;
  margin-top: auto;

  .signout-btn {
    & span {
      color: map-get($colors, 'white');
    }
    & svg path {
      fill: map-get($colors, 'white');
    }
  }

  .copylight-container {
    margin-top: 28px;
  }
}
