@import '/src/assets/styles/colors';

.container {
  margin-top: 16px;
  position: absolute;
  z-index: 1;

  min-width: 100%;
  width: max-content;
  height: max-content;

  background: map-get($colors, 'gray-light-color');
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;

  display: flex;
  flex-direction: column;

  visibility: hidden;

  &.visible {
    visibility: visible;
  }

  &.size--small {
    margin-top: 4px !important;
    min-width: 130% !important;
    padding: 8px;

    .items {
      .item {
        &.margin {
          margin-bottom: 8px;
        }
      }
    }
  }

  &.size--medium {
    padding: 16px;
  }

  &.size--large {
    padding: 24px;
  }

  &.align--left {
    left: 0;
  }

  &.align--right {
    right: 0;
  }

  &.align--center {
    left: 50%;
    transform: translateX(-50%);
  }
}
