@import '/src/assets/styles/colors';

.container {
  appearance: none;
  outline: none !important;

  position: relative;

  padding: 16px;

  resize: none;

  border-radius: 6px;
  color: map-get($colors, 'black');
  border: 2px solid map-get($colors, 'gray-color');

  caret-color: map-get($colors, 'primary-color');

  &::placeholder {
    color: map-get($colors, 'gray-color');
  }

  &:focus-visible {
    outline: none !important;
    border-color: map-get($colors, 'primary-color');
  }

  &.color-primary {
    &.active:not(:focus-visible) {
      color: map-get($colors, 'primary-color');
      border-color: map-get($colors, 'primary-color');
    }
  }
}
